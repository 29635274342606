import { MagicUseCase } from '../magicUseCase';

export class SetUnitIdUseCase extends MagicUseCase {
  protected async runLogic(unitId: string) {
    const application = this.getState().user.rentalApplication.application;

    if (application && unitId && !(application.interestedUnits && application.interestedUnits.length > 0)) {
      application.interestedUnits = [unitId];
    }
  }
}
