import { useParams, useSearchParams } from '@solidjs/router';
import { Component, createEffect, createSignal, For, onMount, Show } from 'solid-js';
import IconGovernmentId from '~/assets/icons/userId.svg';
import brokenImage from '~/assets/images/brokenImage.svg';
import {
  BANK_STATEMENT,
  DATE_OF_BIRTH,
  EMAIL,
  FIRST_NAME,
  GOVERNMENT_ID,
  LAST_NAME,
  NEXT,
  PHONE,
  SSN,
  UPLOAD_BANK_STATEMENT,
  UPLOAD_GOVERNMENT_ID,
} from '~/assets/strings';
import LabeledGroup from '~/components/common/Inputs/LabeledGroup';
import LabeledTextInput from '~/components/common/Inputs/LabeledTextInput';
import { TintedSVG } from '~/components/common/TintedSVG';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import { Button } from '~/components/common/buttons';
import { DropZone } from '~/components/common/forms/Dropzone';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { FileDescriptor, FileType } from '~/types/RentalApplication';
import { RentalApplicationStep } from '~/types/RentalApplicationSteps';
import { RemoveRentalApplicationFileUseCase } from '~/use-cases/rental-applications/application-steps/removeRentalApplicationFileUseCase';
import { UpdateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/updateRentalApplicationInfoUseCase';
import { ValidateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalApplicationInfoUseCase';
import { GetRentalApplicationUseCase } from '~/use-cases/rental-applications/getRentalApplicationUseCase';
import { GoToNextStepUseCase } from '~/use-cases/rental-applications/goToNextStepUseCase';
import { SetUnitIdUseCase } from '~/use-cases/rental-applications/initUnitFromUrlUseCase';
import { uuid } from '~/utils/tools';

export const ApplicantInformationStep: Component = () => {
  const { t } = useLocalization();
  const params = useParams();
  const [files, setFiles] = createSignal<FileDescriptor[]>([]);
  const [searchParams] = useSearchParams<{
    unitId: string;
  }>();

  let govIdRef: HTMLInputElement | undefined;
  let bankStatementRef: HTMLInputElement | undefined;
  let firstNameRef: HTMLInputElement | undefined;
  let lastNameRef: HTMLInputElement | undefined;
  let emailRef: HTMLInputElement | undefined;
  let phoneRef: HTMLInputElement | undefined;
  let ssnRef: HTMLInputElement | undefined;
  let dateOfBirthRef: HTMLInputElement | undefined;

  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: getRentalApplication, isLoading: isLoadingApplication } = useUseCase(GetRentalApplicationUseCase);
  const { execute: updateRentalApplication, isLoading: isUpdatingApplication } = useUseCase(UpdateRentalApplicationInfoUseCase);
  const { execute: removeFiles } = useUseCase(RemoveRentalApplicationFileUseCase);
  const { execute: goToNextStep, isLoading: isGoingToNextStep } = useUseCase(GoToNextStepUseCase);
  const { execute: validateRentalApplication, didSucceed: didSucceedValidation } = useUseCase(ValidateRentalApplicationInfoUseCase);

  const { execute: setUnitId } = useUseCase(SetUnitIdUseCase);

  onMount(async () => {
    await getRentalApplication({ id: params.id, password: params.password });
    setUnitId(searchParams.unitId);
  });

  function handleFiles(type: FileType, fileList?: FileList | null) {
    const newImages: FileDescriptor[] = [];
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        const id = uuid();
        const fileDescriptor: FileDescriptor = {
          id,
          type,
          file,
          name: file.name,
          wasUploaded: false,
          isImage: file.type.includes('image'),
        };
        newImages.push(fileDescriptor);
      }
    }
    setFiles([...files(), ...newImages]);
    updateRentalApplication({ files: files() });
  }

  const removeApplicationFile = async (id: string) => {
    await removeFiles(id);
    setFiles(files().filter((file) => file.id !== id));
    if (govIdRef?.value) {
      govIdRef.value = '';
    }
    if (bankStatementRef?.value) {
      bankStatementRef.value = '';
    }
  };

  createEffect(() => {
    if (!didSucceedValidation() || presentableRentalApplication()?.errors?.hasApplicantInfoError) {
      return;
    }
    goToNextStep({ currentStep: RentalApplicationStep.ApplicantInfo, credentials: { id: params.id, password: params.password } });
  });

  const updateInfo = () => {
    updateRentalApplication({
      firstName: firstNameRef?.value,
      lastName: lastNameRef?.value,
      email: emailRef?.value,
      phone: phoneRef?.value,
      ssn: ssnRef?.value,
      dateOfBirth: dateOfBirthRef?.value,
    });
  };

  const maxBirthDate = () => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  };

  return (
    <Show
      when={!isLoadingApplication() && !isGoingToNextStep()}
      fallback={<div class="flex h-section3 flex-1 grow ">{isUpdatingApplication() ? <ActivityIndicator /> : <ActivityIndicator />}</div>}>
      <div class="flex flex-col">
        <div class="flex-1 overflow-y-auto p-6">
          <div class="grid grid-cols-2 gap-5 py-4">
            <LabeledTextInput
              label={t(FIRST_NAME)}
              required
              error={presentableRentalApplication()?.errors?.firstNameError}
              ref={firstNameRef}
              value={presentableRentalApplication()?.firstName || ''}
              onChange={() => updateInfo()}
            />
            <LabeledTextInput
              label={t(LAST_NAME)}
              required
              error={presentableRentalApplication()?.errors?.lastNameError}
              ref={lastNameRef}
              value={presentableRentalApplication()?.lastName || ''}
              onChange={() => updateInfo()}
            />
            <LabeledTextInput
              label={t(EMAIL)}
              required
              error={presentableRentalApplication()?.errors?.emailError}
              ref={emailRef}
              value={presentableRentalApplication()?.email || ''}
              onChange={() => updateInfo()}
            />
            <LabeledTextInput
              label={t(PHONE)}
              required
              error={presentableRentalApplication()?.errors?.phoneError}
              ref={phoneRef}
              value={presentableRentalApplication()?.phoneNumber || ''}
              onChange={() => updateInfo()}
            />
            <LabeledTextInput
              label={t(SSN)}
              required
              error={presentableRentalApplication()?.errors?.ssnError}
              ref={ssnRef}
              value={presentableRentalApplication()?.ssn || ''}
              onChange={() => updateInfo()}
            />
            <LabeledTextInput
              type="date"
              required
              label={t(DATE_OF_BIRTH)}
              error={presentableRentalApplication()?.errors?.dateOfBirthError}
              ref={dateOfBirthRef}
              value={presentableRentalApplication()?.dateOfBirth || ''}
              onChange={() => updateInfo()}
              max={maxBirthDate()}
            />
          </div>
          <div class="grid grid-cols-2 gap-5 py-4">
            <LabeledGroup class="col-span-full sm:col-span-1" label={t(GOVERNMENT_ID)}>
              <div class={`flex ${!presentableRentalApplication()?.governmentIdFiles ? 'flex-row' : 'flex-col'} gap-4`}>
                <DropZone
                  class="hover:bg-primary-color/10 flex h-28 cursor-pointer flex-col items-center justify-center rounded-lg border border-dashed border-primary-color pt-3"
                  activeClass="bg-primary-color/5"
                  onDropFiles={(files?: FileList) => handleFiles(FileType.Identification, files)}
                  onClick={() => govIdRef?.click()}>
                  <TintedSVG svg={IconGovernmentId} color="var(--primary-color)" />
                  <div class="text-sm font-medium text-primary-color"> {t(UPLOAD_GOVERNMENT_ID)}</div>
                  <input
                    type="file"
                    multiple
                    hidden
                    ref={govIdRef}
                    onChange={(e) => handleFiles(FileType.Identification, e.target.files)}
                  />
                </DropZone>
              </div>
              <Show when={presentableRentalApplication()?.governmentIdFiles}>
                <For each={presentableRentalApplication()?.governmentIdFiles}>
                  {(file) => (
                    <div class="text-center">
                      {file.isImage ? (
                        <div class="relative mx-1 mb-1 inline-block min-h-80 min-w-80 cursor-pointer">
                          <img
                            class="size-full cursor-pointer rounded-lg border border-black bg-white object-cover"
                            src={file.path}
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = brokenImage;
                            }}
                          />
                          <div
                            class="absolute right-4 top-5 -mr-2 -mt-3 flex size-6 items-center justify-center rounded-full border border-gray-400 bg-gray-200 text-sm text-gray-400 hover:border-white hover:bg-red-500 hover:text-white"
                            onClick={() => removeApplicationFile(file.id)}>
                            X
                          </div>
                        </div>
                      ) : (
                        <div class="relative rounded-md bg-input py-2">
                          <div class="text-sm font-medium">{file.name}</div>
                          <div
                            class="absolute right-4 top-5 -mr-2 -mt-3 flex size-6 items-center justify-center rounded-full border border-gray-400 bg-gray-200 text-sm text-gray-400 hover:border-white hover:bg-red-500 hover:text-white"
                            onClick={() => removeApplicationFile(file.id)}>
                            X
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </For>
              </Show>
            </LabeledGroup>
            <LabeledGroup class="col-span-full sm:col-span-1" label={t(BANK_STATEMENT)}>
              <div class={`flex ${!presentableRentalApplication()?.bankStatementFiles ? 'flex-row' : 'flex-col'} gap-4`}>
                <DropZone
                  class="hover:bg-primary-color/10 flex h-28 cursor-pointer flex-col items-center justify-center rounded-lg border border-dashed border-primary-color pt-3"
                  activeClass="bg-primary-color/5"
                  onDropFiles={(files?: FileList) => handleFiles(FileType.BankStatement, files)}
                  onClick={() => bankStatementRef?.click()}>
                  <TintedSVG svg={IconGovernmentId} color="var(--primary-color)" />
                  <div class="text-sm font-medium text-primary-color"> {t(UPLOAD_BANK_STATEMENT)}</div>
                  <input
                    type="file"
                    multiple
                    hidden
                    ref={(element) => (bankStatementRef = element)}
                    onChange={(e) => handleFiles(FileType.BankStatement, e.target.files)}
                  />
                </DropZone>
              </div>
              <Show when={presentableRentalApplication()?.bankStatementFiles}>
                <For each={presentableRentalApplication()?.bankStatementFiles}>
                  {(file) => (
                    <div class="text-center">
                      {file.isImage ? (
                        <div class="relative mx-1 mb-1 inline-block cursor-pointer">
                          <img
                            class="size-full cursor-pointer rounded-lg border border-black bg-white object-cover"
                            src={file.path}
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = brokenImage;
                            }}
                          />
                          <div
                            class="absolute right-4 top-5 -mr-2 -mt-3 flex size-6 items-center justify-center rounded-full border border-gray-400 bg-gray-200 text-sm text-gray-400 hover:border-white hover:bg-red-500 hover:text-white"
                            onClick={() => removeApplicationFile(file.id)}>
                            X
                          </div>
                        </div>
                      ) : (
                        <div class="rounded-md bg-input py-2">
                          <div class="text-sm font-medium">{file.name}</div>
                        </div>
                      )}
                    </div>
                  )}
                </For>
              </Show>
            </LabeledGroup>
          </div>
        </div>
      </div>
      <div class="fixed bottom-0 left-0 z-20 w-full border-t border-gray-200 bg-white px-4 py-3">
        <div class="flex items-end justify-end">
          <Button type="button" onClick={validateRentalApplication}>
            {t(NEXT)}
          </Button>
        </div>
      </div>
    </Show>
  );
};
